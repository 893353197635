<!--
 * @Editors: pan long
 -->
<template>
  <div>
    <Header title="cili"></Header>
    <BannerTitle title="cili"></BannerTitle>
    <div class="clBox">
      <ul class="wow slideInUp topTip">
        <li>
          <img src="@/assets/images/v2/clyq-1.png" alt="" />
          <b>{{ $t("cili.listTop.tips1") }}</b>
          <span>{{ $t("cili.listTop.desc1") }}</span>
        </li>
        <li>
          <img src="@/assets/images/v2/clyq-2.png" alt="" />
          <b>{{ $t("cili.listTop.tips2") }}</b>
          <span>{{ $t("cili.listTop.desc2") }}</span>
        </li>
        <li>
          <img src="@/assets/images/v2/clyq-3.png" alt="" />
          <b>{{ $t("cili.listTop.tips3") }}</b>
          <span>{{ $t("cili.listTop.desc3") }}</span>
        </li>
        <li>
          <img src="@/assets/images/v2/clyq-4.png" alt="" />
          <b>{{ $t("cili.listTop.tips4") }}</b>
          <span>{{ $t("cili.listTop.desc4") }}</span>
        </li>
        <li>
          <img src="@/assets/images/v2/clyq-5.png" alt="" />
          <b>{{ $t("cili.listTop.tips5") }}</b>
          <span>{{ $t("cili.listTop.desc5") }}</span>
        </li>
      </ul>
      <div class="wow slideInUp midBox">
        <ul class="wow slideInUp MidTip">
          <li>
            <div class="imgPos">
              <img class="topPic1" src="@/assets/images/v2/clyq-6.png" alt="" />
              <img class="topPic2" src="@/assets/images/v2/phone.png" alt="" />
              <img class="topPic3" src="@/assets/images/v2/shadow.png" alt="" />
            </div>
            <div class="wow slideInUp MidContent">
              <div>{{ $t("cili.ListMiddle.tips1") }}</div>
              <p>{{ $t("cili.ListMiddle.desc1") }}</p>
            </div>
          </li>
          <li class="middleLi">
            <div class="wow slideInUp MidContent">
              <div>{{ $t("cili.ListMiddle.tips2") }}</div>
              <p>{{ $t("cili.ListMiddle.desc2") }}</p>
            </div>
            <img class="midPic" src="@/assets/images/v2/clyq-7.png" alt="" />
          </li>
          <li>
            <img class="botPic" src="@/assets/images/v2/clyq-8.png" alt="" />
            <div class="wow slideInUp MidContent">
              <div>{{ $t("cili.ListMiddle.tips3") }}</div>
              <p>{{ $t("cili.ListMiddle.desc3") }}</p>
            </div>
          </li>
        </ul>
      </div>
      <div class="btoTit">{{ $t("cili.listBottom.title") }}</div>
      <ul style="margin-bottom:160px;" class="wow slideInUp topTip">
        <li>
          <img src="@/assets/images/v2/jl-18.png" alt="" />
          <b>{{ $t("cili.listBottom.tips1") }}</b>
          <span style="width:320px;">{{ $t("cili.listBottom.desc1") }}</span>
        </li>
        <li>
          <img src="@/assets/images//v2/jl-19.png" alt="" />
          <b>{{ $t("cili.listBottom.tips2") }}</b>
          <span style="width:320px;">{{ $t("cili.listBottom.desc2") }}</span>
        </li>
        <li>
          <img src="@/assets/images/v2/jl-20.png" alt="" />
          <b>{{ $t("cili.listBottom.tips3") }}</b>
          <span style="width:320px;">{{ $t("cili.listBottom.desc3") }}</span>
        </li>
      </ul>
    </div>
    <Hotline></Hotline>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from "../../components/header.vue";
import BannerTitle from "../../components/bannerTitle.vue";
import Footer from "../../components/footer.vue";
import Hotline from "../../components/hotline.vue";
export default {
  name: "linyuan",
  components: {
    Header,
    BannerTitle,
    Footer,
    Hotline
  }
};
</script>

<style lang="scss">
.clBox {
  width: 1300px;
  margin: auto;
  
  .topTip {
    display: flex;
    justify-content: space-between;

    li{
      // flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;

      img {
        width: 72px;
        height: 72px;
      }

      b {
        font-size:20px;
        font-family:PingFangSC-Semibold,PingFang SC;
        font-weight:600;
        margin-top: 30px;
      }

      span {
        width:202px;
        height:78px;
        font-size:14px;
        font-family:PingFangSC-Regular,PingFang SC;
        font-weight:400;
        color:rgba(153,153,153,1);
        line-height:26px;
        text-align: center;
        margin-top: 13px;
      }
    }
  }

  .midBox {
    width: 800px;
    margin: auto;

    .MidTip {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-items: center;
      margin-top:115px;
      margin-bottom: 166px;

      li {
        display: flex;
        align-items: center;
        justify-content: space-between;


        .midPic {
          width:432px;
          height:538px;
          margin-left: 214px;
        }

        .botPic {
          width:417px;
          height:349px;
          margin-right: 134px;
        }

        .imgPos {
          width: 301px;
          height: 627px;
          position: relative;
          margin-right: 230px;

          .topPic1 {
            width: 282px;
            height: 606px;
            position: absolute;
            z-index: 10;
            left: 10px;
          }
          .topPic2 {
            width:301px;
            height:627px;
            position: absolute;
            z-index:10;
          }
          .topPic3 {
            width: 407px;
            height: 775px;
            position: absolute;
            left: 11px;
            top: -2px;
          }
        }

        .MidContent {
          width: 360px;
          display: flex;
          align-items: center;
          flex-direction: column;
          justify-items: center;
          justify-content: space-between;

          div{
            width:360px;
            // height:112px;
            font-size:40px;
            font-family:PingFangSC-Semibold,PingFang SC;
            font-weight:600;
            color:rgba(51,51,51,1);
            line-height:56px;
          }

          p{
            width:358px;
            height:56px;
            font-size:16px;
            font-family:PingFangSC-Regular,PingFang SC;
            font-weight:400;
            color:rgba(153,153,153,1);
            line-height:30px;
            margin-top: 18px;
          }
        }
      }

      .middleLi {
        margin-top: 93px;
        margin-bottom: 167px;
      }
    }
  }

  .btoTit {
    height:56px;
    text-align: center;
    line-height: 56px;
    font-size:40px;
    font-family:PingFangSC-Semibold,PingFang SC;
    font-weight:600;
    color:rgba(51,51,51,1);
    margin-bottom: 90px;
  }
}
</style>
